<!-- eslint-disable vue/no-v-html -->
<template>
  <v-card
    v-if="$route.query.id"
    :key="noteKey"
    class="encounter-note"
  >
    <!-- title -->
    <v-card-title class="d-print-none">
      <!-- Close -->
      <btn
        label="Close"
        color="secondary"
        :icon="icons.mdiClose"
        @click="closeEncounterNote"
      ></btn>

      <v-spacer></v-spacer>
      {{ title }}
      <v-spacer></v-spacer>

      <!-- Calculate Payment -->
      <btn
        label="Calculate"
        color="success"
        class="encounter-note-sign-sync"
        offline-disable
        :icon="icons.mdiCurrencyUsd"
        @click="calculatePayment"
      ></btn>
    </v-card-title>

    <Attachments
      :is-signed="encounter.is_signed"
      :disabled="addingAddendum"
      :patient-id="patient.id"
      class="d-print-none"
      :encounter-attachments.sync="patient.all_attachments"
    ></Attachments>

    <v-card-actions class="d-print-none">
      <!-- Redact -->
      <btn
        v-if="!isRedacted"
        label="Redact"
        color="accent"
        :icon="icons.mdiMarker"
        :disabled="!encounter.is_signed || addingAddendum"
        @click="toggleRedact"
      ></btn>

      <!-- Unredact -->
      <btn
        v-else
        label="Unredact"
        color="secondary"
        outlined
        :icon="icons.mdiMarkerCancel"
        :disabled="!encounter.is_signed || addingAddendum"
        @click="toggleRedact"
      ></btn>

      <!-- Print -->
      <btn
        label="Print"
        color="primary"
        :icon="icons.mdiPrinter"
        :disabled="!encounter.is_signed || addingAddendum"
        @click="printNote"
      ></btn>

      <!-- PDF -->
      <!--
      <btn
        label="Create PDF"
        color="primary"
        :icon="icons.mdiFileDocumentMultiple"
        :disabled="!encounter.is_signed || addingAddendum"
        @click="getPDF"
      ></btn>
      -->

      <v-spacer></v-spacer>

      <!-- Sign Note -->
      <btn
        v-if="!encounter.is_signed"
        label="Sign Note"
        color="warning"
        class="encounter-note-sign-sync"
        :icon="icons.mdiFileSign"
        :disabled="!encounter.is_validated || addingAddendum || (encounter.is_deferred_care && !encounter.deferred_care_reason)"
        @click="signNote"
      ></btn>

      <!-- Unsign Note -->
      <btn
        v-else-if="!encounter.is_synced"
        label="Unsign Note"
        color="warning"
        class="encounter-note-sign-sync"
        :icon="icons.mdiUndoVariant"
        :disabled="encounter.is_synced || addingAddendum"
        @click="unsignNote"
      ></btn>

      <!-- Show signed status -->
      <icon-value
        v-else
        :icon="encounter.is_signed ? icons.mdiClipboardCheck : icons.mdiCloseCircle"
        :value="encounter.is_signed ? 'Signed' : 'Not Signed'"
        :color="encounter.is_signed ? 'success' : 'error'"
        class="encounter-note-sign-sync"
      ></icon-value>

      <!-- Sync Note -->
      <btn
        v-if="encounter.is_signed && !encounter.is_synced"
        label="Sync Note"
        color="error"
        class="encounter-note-sign-sync"
        :icon="icons.mdiFileSync"
        :disabled="!encounter.is_signed || encounter.is_synced || addingAddendum"
        offline-disable
        @click="syncNoteQuery"
      ></btn>

      <!-- Show synced status -->
      <icon-value
        v-else
        :icon="encounter.is_synced ? icons.mdiCloudCheckVariant : icons.mdiCloseCircle"
        :value="encounter.is_synced ? 'Synced' : 'Not Synced'"
        :color="encounter.is_synced ? 'success' : 'error'"
        class="encounter-note-sign-sync"
      ></icon-value>
    </v-card-actions>

    <!-- Redact Heading -->
    <v-expand-transition>
      <div v-show="isRedacted">
        <NoteSection
          label="Redacted Document"
          class="mt-1 mb-3"
          heading
        ></NoteSection>
      </div>
    </v-expand-transition>

    <PodiatryPreview
      :encounter="encounter"
    >
    </PodiatryPreview>
    <v-card-actions
      v-if="addingAddendum"
      class="d-print-none pb-0"
    >
      <text-area
        v-model="addendum"
        label="Enter addendum..."
        class="col-sm-12"
      ></text-area>
    </v-card-actions>
    <v-card-actions
      v-if="addingAddendum"
      class="d-print-none"
    >
      <!-- Cancel Addendum -->
      <btn
        label="Cancel"
        color="secondary"
        :icon="icons.mdiCancel"
        @click="cancelAddendum"
      ></btn>
      <v-spacer></v-spacer>
      <!-- Save Addendum -->
      <btn
        label="Save Addendum"
        color="error"
        :icon="icons.mdiContentSaveEdit"
        :disabled="!addendum"
        @click="saveAddendum"
      ></btn>
    </v-card-actions>
    <v-card-actions
      v-else
      class="d-print-none"
    >
      <!-- Close -->
      <btn
        label="Close"
        color="secondary"
        :icon="icons.mdiClose"
        @click="closeEncounterNote"
      ></btn>
      <!-- Delete Deferred Care Note -->
      <btn
        v-if="encounter.is_deferred_care"
        label="Delete"
        color="error"
        :icon="icons.mdiTrashCan"
        :disabled="encounter.is_signed"
        @click="deleteDeferredEncounterNote"
      ></btn>
      <v-spacer></v-spacer>
      <!-- Add Addendum -->
      <btn
        label="Add Addendum"
        color="success"
        :icon="icons.mdiNotePlus"
        :disabled="!encounter.is_synced"
        @click="addAddendum"
      ></btn>
    </v-card-actions>
    <!-- dump object -->
    <div
      v-if="$authUser.user() && $authUser.user().is_superuser"
      class="btn-top-center"
    >
      <btn
        label="Dump"
        :icon="icons.mdiDownload"
        x-small
        color="secondary"
        @click="$store.commit('encounters/updateEncounter', { encounter, patient });
                $router.push({ name: 'dump-encounter', query: { id: encounter.id } })"
      ></btn>
    </div>
  </v-card>
</template>

<script>
import Encounter from '@/mixins/Encounter'
import EncounterNote from '@/mixins/EncounterNote'
import Podiatry from '@/mixins/practice-types/Podiatry'
import PodiatryFinalNote from '@/mixins/final-note/Podiatry'
import PodiatryPreview from '@/views/notes/encounter-preview/types/Podiatry.vue'

export default {
  mixins: [Podiatry, Encounter, EncounterNote, PodiatryFinalNote],
  components: {
    PodiatryPreview,
  },
  data() {
    return {
      final_note: null,
    }
  },
  computed: {
    title() {
      if (this.encounter.is_deferred_care) return 'Podiatry Deferred Encounter'

      return this.encounter.visit_type === 'New'
        ? 'Podiatry Assessment'
        : 'Podiatry Follow-up Assessment'
    },
    sortedAddendums() {
      const addendums = [...this.encounter.addendums]

      return addendums.sort((a, b) => (a.created > b.created && 1) || -1)
    },
  },
  watch: {
    final_note() {
      if (
        (!this.encounter.is_signed || this.$custom.isEmpty(this.encounter.final_note))
        && this.final_note !== null
      ) {
        this.encounter.final_note = this.final_note
          .replaceAll('. ', '.&nbsp;')
          .replaceAll('<br>\t', '<br>&nbsp;&nbsp;')
      }
    },
  },
  mounted() {
    if (!this.encounter.is_deferred_care) {
      this.generateFinalNote()
    }
  },
  methods: {
    providerName(id) {
      const provider = this.$store.getters['users/getById'](id)

      if (provider) {
        const authId = this.$store.getters['auth/user'].id
        const userSuffix = id === authId ? this.$store.getters['auth/getSuffix'] : ''

        if (userSuffix) {
          return `${provider.first_name} ${provider.last_name}, ${userSuffix}`
        } else {
          return `${provider.first_name} ${provider.last_name}`
        }
      } else {
        return 'Unknown Provider'
      }
    },
  },
}
</script>
